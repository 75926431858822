/* global axios */
import ApiClient from './ApiClient';
import store from '../store';

class KanbanAPI extends ApiClient {
  constructor() {
    super('kanban_items', { accountScoped: true });
  }

  get accountId() {
    return store.state.auth.currentUser.account_id;
  }

  getItems() {
    return axios.get(this.url);
  }

  getItem(conversationId) {
    return axios.get(`${this.url}/${conversationId}`);
  }

  createItem(data) {
    try {
      console.log('Criando item do kanban:', data);
      return axios.post(this.url, data);
    } catch (error) {
      console.error('Erro ao criar item do kanban:', error);
      throw error;
    }
  }

  updateItemStructure(itemId, structure) {
    try {
      console.log('Atualizando estrutura do item:', itemId, structure);
      return axios.post(
        `${this.url}/${itemId}/update_structure`,
        structure
      );
    } catch (error) {
      console.error('Erro ao atualizar estrutura:', error);
      throw error;
    }
  }

  updateItemDetails(itemId, details) {
    try {
      console.log('Atualizando detalhes do item:', itemId, details);
      return axios.post(
        `${this.url}/${itemId}/update_details`,
        details
      );
    } catch (error) {
      console.error('Erro ao atualizar detalhes:', error);
      throw error;
    }
  }

  deleteItem(itemId) {
    return axios.delete(`${this.url}/${itemId}`);
  }

  startTimer(itemId) {
    return axios.post(`${this.url}/${itemId}/start_timer`);
  }

  stopTimer(itemId) {
    return axios.post(`${this.url}/${itemId}/stop_timer`);
  }

  fetchConfig() {
    return axios.get('/super_admin/app_config', {
      params: { config: 'kanban' },
      headers: {
        'Accept': 'application/json'
      }
    });
  }
}

export default new KanbanAPI();
